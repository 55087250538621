<template>

  <div class="row" ref="submitForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <div class="col-12">
          <div class="card card-wizard" id="wizardCard">
            <form-wizard shape="tab"
                         :title="formTitle"
                         :subtitle="''"
                         error-color="#FB404B"
                         color="#35495E">
              <tab-content title="French Content"
                           class="col-12"
                           icon="nc-icon nc-caps-small"
                           :before-change="() => validateStep('englishStep')">
                <english-step :formData="formData ? formData['fr'] : defaultFormData['fr']"
                              :builderData="builderData"
                              :editMode="editMode"
                              ref="englishStep"
                              @on-validated="onStepValidated" @submit-step="submitStep"></english-step>
              </tab-content>
              <tab-content v-for="(lang, index) in languageList" :key="index" :title="lang.label+' Content'"
                           class="col-12"
                           icon="nc-icon nc-caps-small"
                           :before-change="() => validateStep(lang.value)">
                <other-languages-step
                  :formData="formData ? formData[lang.value] : defaultFormData[lang.value]"
                  :editMode="editMode"
                  :isSubmit="languageList.length - 1 === index"
                  :lang="lang" :ref="lang.value" @on-validated="onStepValidated"
                  @submit-step="submitStep"></other-languages-step>
              </tab-content>

              <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
              <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
              <button slot="finish" class="btn btn-success btn-fill btn-wd" :disabled="entityNotFoundError || submitting">Finish</button>
            </form-wizard>
          </div>
        </div>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {mapGetters} from "vuex";
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EnglishStep from "./Wizard/EnglishStep";
import OtherLanguagesStep from "./Wizard/OtherLanguagesStep";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormWizard,
    TabContent,
    OtherLanguagesStep,
    EnglishStep,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {},
      defaultFormData: {
        title: "",
        subtitle: "",
        url: "",
        content: "",
        gallery: [],
        list_items: "",
        image: "",
        banner_image: "",
        date: "",
        is_active: "",
        sort_order: "",
        related_articles: [],
        tags: [],
        category_id: "",
        type_id: "",
        expert_id: "",
      },


      builderData: {
        categoryList: [],
        typeList: [],
        tagList: [],
        expertList: [],
        articleList: [],
      },
      wizardModel: {},

    };
  },

  computed: {
    ...mapGetters({
      languageList: "global/languageList"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.submitForm
    });
    this.setNullFormData();
    this.id = this.$route.params['id'];
    let data = {
      id: this.id
    };
    this.axios.post("articles/builder", data).then((response) => {
      this.builderData.articleList = response.data.articles;
      this.builderData.categoryList = response.data.categories;
      this.builderData.expertList = response.data.experts;
      this.builderData.tagList = response.data.tags;
      this.builderData.typeList = response.data.types;


      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Article";
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = "Add Article";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })



  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("articles/get", data).then((response) => {
        this.formData = response.data;
        this.formData['fr'].tags = this.formData['fr'].tags.map(tag => tag.id);
        this.formData['fr'].related_articles = this.formData['fr'].related_articles.map(article => article.id);
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Article Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("articles/update/" + this.id, this.formData);
        successMessage = "Article Updated Successfully";
      } else {
        request = this.axios.post("articles/create", this.formData);
        successMessage = "Article Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/articles/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    validateStep(ref) {
      if (Array.isArray(this.$refs[ref])){
        return this.$refs[ref][0].validate();
      }else{
        return this.$refs[ref].validate();
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = {...this.wizardModel, ...model}
    },
    submitStep(data, lang, isSubmit){
      this.formData[lang] = data;
      if (isSubmit){
        this.submit();
      }
    },
    setNullFormData(){
      this.defaultFormData['fr'] = {

      };
      this.languageList.forEach(lang => {
        this.defaultFormData[lang] = {

        }
      })
    },
  }
}
</script>
